<template>
  <div id="Page404">
      Страница не существует
  </div>
</template>

<script>
  export default {
    name: "Error404"
  }
</script>

<style scoped>
#Page404{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: red;
  font-family: 'Kosko';
}
</style>